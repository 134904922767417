<template>
  <div class="consortium">
    <div class="top_Img">
      <img src="../../assets/banner/member.jpg" alt="">
      <div class="tips">联合体项目B</div>
    </div>
    <div class="items">
      <div class="list" @click="handleDetails">
        <div class="Img">
          <img src="../../assets/u130.jpg" alt="">
        </div>
        <div class="message">
          <div class="title1">习近平在气候雄心峰会上发表重要讲话</div>
          <div class="title2">国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国</div>
          <div class="time">2020-11-26</div>
        </div>
      </div>
      <div class="list">
        <div class="Img">
          <img src="../../assets/u130.jpg" alt="">
        </div>
        <div class="message">
          <div class="title1">习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话</div>
          <div class="title2">国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国</div>
          <div class="time">2020-11-26</div>
        </div>
      </div>
      <div class="list">
        <div class="Img">
          <img src="../../assets/u130.jpg" alt="">
        </div>
        <div class="message">
          <div class="title1">习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话</div>
          <div class="title2">国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国</div>
          <div class="time">2020-11-26</div>
        </div>
      </div>
      <div class="list">
        <div class="Img">
          <img src="../../assets/u130.jpg" alt="">
        </div>
        <div class="message">
          <div class="title1">习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话</div>
          <div class="title2">国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国</div>
          <div class="time">2020-11-26</div>
        </div>
      </div>
      <div class="list">
        <div class="Img">
          <img src="../../assets/u130.jpg" alt="">
        </div>
        <div class="message">
          <div class="title1">习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话习近平在气候雄心峰会上发表重要讲话</div>
          <div class="title2">国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国国家主席习近平12日在气候雄心峰会上通过视频发表题为《继往开来，开启全球应对气候变化新征程》的重要讲话，宣布中国</div>
          <div class="time">2020-11-26</div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <a-pagination :default-current="6" :total="500" />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    // 查看详情
    handleDetails(){
      this.$router.push({path:'messageDetails',query:{isTop:'Top'}});
    }
  },
  mounted(){

  }
}
</script>

<style lang="scss" scoped>
  .consortium{
    .top_Img{position: relative;
      img{width: 100%;max-width: 100%;height: 100%;}
      .tips{position: absolute;top:50%;left:15%;font-size: 40px;font-weight: bold;color: #fff;}
    }
    .items{padding: 12px;margin:0 auto;margin-top:20px;max-width: 1400px;
      .list{display: flex;border-bottom:1px solid #ececec;margin-bottom:15px;padding-bottom:15px;height:146px;cursor: pointer;
        img{width: 200px;height: 130px;}
        .message{margin-left: 30px;display: flex;flex-direction: column;justify-content: space-between;
          
        }
      }
    }
    .title1{font-size: 20px;font-weight: bold;color: #333;}
      .title2,.time{font-size: 12px;color: #333;}
    .pagination{max-width: 1400px;margin: 0 auto;display: flex;justify-content: flex-end;}
  }
</style>